<template>
  <v-container fluid class="px-0">
    <NotificationList />
  </v-container>
</template>

<script>
import NotificationList from "@/components/layout/notifications/NotificationList";
export default {
  name: "Notifications",
  components: { NotificationList }
};
</script>

<style scoped></style>
