<template>
  <v-container fluid class="px-0">
    <ItemDetailNotification
      :loading="loading"
      :notification="getNotification"
    />

    <v-container v-if="!loading" class="mt-8">
      <v-row>
        <!-- <v-col cols="12" class="text-center mb-4">
          <h4 class="font-weight-bold label--text darken-2">
            {{ $t("home_notifications") }}
          </h4>
        </v-col> -->

        <v-col cols="12" class="text-center">
          <v-btn
            class="label--text darken-1 label-button-list text-none"
            text
            tile
            small
            plain
            :ripple="false"
            to="/notifications"
            @click="$router.go(-1)"
          >
            <v-icon left size="12">$play</v-icon>
            {{ $t("notice_list") }}
          </v-btn>
        </v-col>
      </v-row>
    </v-container>
  </v-container>
</template>

<script>
import ItemDetailNotification from "@/components/ui/ItemDetailNotification";
import dayjs from "@/plugins/dayjs";
import { mapGetters } from "vuex";
export default {
  name: "NotificationDetail",
  // eslint-disable-next-line vue/no-unused-components
  components: { ItemDetailNotification },
  computed: {
    ...mapGetters(["getNotification", "getNotificationRelatedPosts"])
  },
  data() {
    return {
      loading: false,
      date: dayjs().format("YYYY/MM/DD")
    };
  },
  created() {
    this.getDataFromApi();
  },
  methods: {
    getDataFromApi() {
      this.loading = true;
      this.$store
        .dispatch("NOTIFICATION_GET_DETAIL", {
          id: this.$route.params.id
        })
        .finally(() => {
          this.loading = false;
        });
    }
  }
};
</script>

<style scoped></style>
