<template>
  <v-row>
    <v-col cols="12">
      <v-row>
        <v-col cols="12" class="header">
          <h1>通知リスト</h1>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" class="flex-grow-col">
          <a href="">
            <i
              aria-hidden="true"
              class="v-icon notranslate mdi mdi-circle theme--light"
            ></i>
            <span class="note-txt"
              >『2021-6-23（火）株式会社ABC / 3070 天神事務所
              』のタスクが更新されました。</span
            >
            <div class="note-date">変更日 : 2021年6月18日</div>
            <i
              aria-hidden="true"
              class="v-icon notranslate mdi mdi-chevron-right theme--light"
            ></i>
          </a>
        </v-col>
        <v-col cols="12" class="flex-grow-col">
          <a href="">
            <i
              aria-hidden="true"
              class="v-icon notranslate mdi mdi-circle theme--light"
            ></i>
            <span class="note-txt"
              >『株式会社モトクル』に物件が登録されました。</span
            >
            <div class="note-date">変更日 : 2021年6月18日</div>
            <i
              aria-hidden="true"
              class="v-icon notranslate mdi mdi-chevron-right theme--light"
            ></i>
          </a>
        </v-col>
        <v-col cols="12" class="flex-grow-col">
          <a href="">
            <i
              aria-hidden="true"
              class="v-icon notranslate mdi mdi-circle theme--light"
            ></i>
            <span class="note-txt"
              >『株式会社モトクル 』にタスクが登録されました。</span
            >
            <div class="note-date">変更日 : 2021年6月18日</div>
            <i
              aria-hidden="true"
              class="v-icon notranslate mdi mdi-chevron-right theme--light"
            ></i>
          </a>
        </v-col>
        <v-col cols="12" class="flex-grow-col">
          <a href="">
            <i
              aria-hidden="true"
              class="v-icon notranslate mdi mdi-circle theme--light"
            ></i>
            <span class="note-txt"
              >『株式会社モトクル 』にタスクが登録されました。</span
            >
            <div class="note-date">変更日 : 2021年6月18日</div>
            <i
              aria-hidden="true"
              class="v-icon notranslate mdi mdi-chevron-right theme--light"
            ></i>
          </a>
        </v-col>
        <v-col cols="12" class="flex-grow-col">
          <a href="">
            <span class="note-txt"
              >『株式会社モトクル 』にタスクが登録されました。</span
            >
            <div class="note-date">変更日 : 2021年6月18日</div>
            <i
              aria-hidden="true"
              class="v-icon notranslate mdi mdi-chevron-right theme--light"
            ></i>
          </a>
        </v-col>
        <v-col cols="12" class="flex-grow-col">
          <a href="">
            <span class="note-txt"
              >『株式会社モトクル 』にタスクが登録されました。</span
            >
            <div class="note-date">変更日 : 2021年6月18日</div>
            <i
              aria-hidden="true"
              class="v-icon notranslate mdi mdi-chevron-right theme--light"
            ></i>
          </a>
        </v-col>
        <v-col cols="12" class="flex-grow-col">
          <a href="">
            <span class="note-txt"
              >『株式会社モトクル 』にタスクが登録されました。</span
            >
            <div class="note-date">変更日 : 2021年6月18日</div>
            <i
              aria-hidden="true"
              class="v-icon notranslate mdi mdi-chevron-right theme--light"
            ></i>
          </a>
        </v-col>
        <v-col cols="12" class="flex-grow-col">
          <a href="">
            <span class="note-txt"
              >『株式会社モトクル 』にタスクが登録されました。</span
            >
            <div class="note-date">変更日 : 2021年6月18日</div>
            <i
              aria-hidden="true"
              class="v-icon notranslate mdi mdi-chevron-right theme--light"
            ></i>
          </a>
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: "NotificationList",
  data() {
    return {
      message: "",
      inquiryType: ""
    };
  }
};
</script>

<style scoped></style>
<style lang="scss" src="./notification.scss" scoped></style>
