<template>
  <div>
    <!-- <div class="pr-2">
      <v-avatar size="38" class="active"
        ><img src="https://picsum.photos/250/300?image=661"
      /></v-avatar>
      <div class="pl-1 pt-1 text-caption">高山</div>
    </div> -->
    <div class="pr-2 py-1 d-inline-block" v-for="user in users" :key="user.id">
      <v-badge
        :class="getColorClass(user.pivot.is_read)"
        icon="$checkCircleIcon"
        overlap
      >
        <v-avatar size="38"
          ><img :src="userAvatar(user.profile_image_url)"
        /></v-avatar>
      </v-badge>
      <div class="pl-1 pt-1 text-caption">{{ user.last_name }}</div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    users: {
      type: Array,
      default: () => {},
      required: true
    }
  },
  data() {
    return {
      // users: [
      //   {
      //     name: "高山",
      //     avatar: "https://picsum.photos/250/300?image=661",
      //   },
      //   {
      //     name: "森田",
      //     avatar: "https://picsum.photos/250/300?image=663",
      //   },
      //   {
      //     name: "結愛",
      //     avatar: "https://picsum.photos/250/300?image=664",
      //   },
      //   {
      //     name: "春花",
      //     avatar: "https://picsum.photos/250/300?image=665",
      //   },
      // ],
    };
  },
  methods: {
    userAvatar(image) {
      return image
        ? image
        : require("@/assets/images/person.jpg");
    },
    getColorClass(status) {
      return status == 1 ? "green-check" : "grey-check";
    }
  }
};
</script>

<style scoped>
.active {
  border: 3px solid #f2994a;
}
::v-deep .grey-check .v-badge__badge {
  color: #bcbcbc !important;
  background-color: #fff !important;
}

::v-deep .green-check .v-badge__badge {
  color: #00b216 !important;
  background-color: #fff !important;
}
</style>
